/**
 * Confirm.
 */
window.confirm = function(button) {
    BootstrapDialog.show({
        type: BootstrapDialog.TYPE_DEFAULT,
        title: button.attr("data-title"),
        message: button.attr("data-message"),
        buttons: [ {
            label: __("Cancel"),
            cssClass: "btn-default m-r-1 ignore-unsaved",
            action: function(dialog) {
                dialog.close();
            }
        }, {
            label: __("Yes"),
            cssClass: "btn-success ignore-unsaved",
            action: function(dialog) {
                window.location.href = button.attr("data-url");
                dialog.close();
            }
        } ]
    });
};
