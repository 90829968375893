/**
 * Apis Azure Token Detail Formatter.
 */
window.apisAzureTokenDetailFormatter = function(value, row) {
    let html = "";
    if (row.access_token) {
        html += '<div class="form-group">';
        html += "<label>"+__("Access Token:")+"</label>";
        html += '<textarea class="form-control" rows="3">'+row.access_token+"</textarea>";
        html += "</div>";
    }
    return html;
};
