window.prevSelectedFilters = [];

/**
 * Set Filtered Labels.
 */
window.setFilteredLabels = function (filter) {
    let html = '';
    let id = $(filter).attr('id');
    if ($(filter).attr('type') === 'text') {
        let selectedOption = $(filter).val();
        if (!selectedOption) {
            return;
        }
        // Remove selected value from array
        prevSelectedFilters.splice($.inArray(id, prevSelectedFilters) ,1 );
        // Remove label
        $('#filtered-' + id).remove();
        let html = '';
        prevSelectedFilters.push(id);
        html += '<span class="badge bg-primary float-start me-1 p-2"';
        html += 'data-filter="' + id + '" ';
        html += 'id="filtered-' + id + '" ';
        html += 'data-selected-option="' + selectedOption + '">';
        html += $(filter).val();
        html += '<i class="fa fa-times remove-filtering ms-2"></i>';
        html += '</span>';

        $('.label-filtering').append(html);

    } else {
        $(filter).find('option:selected').each(function (key, option) {
            let selectedOption = $(option).val();
            if (!selectedOption) {
                return true;
            }
            if ( $.inArray(id + '_' + selectedOption, prevSelectedFilters) < 0) {
                let html = '';
                prevSelectedFilters.push(id + '_' + selectedOption);
                html += '<span class="badge bg-primary float-start me-1 p-2"';
                html += 'data-filter="' + id + '" ';
                html += 'data-filter="' + id + '" ';
                html += 'data-selected-option="' + selectedOption + '">';
                html += $(option).text();
                html += '<i class="fa fa-times remove-filtering ms-2"></i>';
                html += '</span>';
                $('.label-filtering').append(html);
            }
        });
    }
}

/**
 * Remove Filtering.
 */
window.removeFiltering = function (clickedFilterItem) {
    let filter = $(clickedFilterItem).data('filter');
    let selectedOption = $(clickedFilterItem).data('selected-option');
    // Unselect value
    $('#' + filter + ' option[value="' + selectedOption + '"]')
        .prop('selected', false)
        .trigger('chosen:updated');
    // Remove selected value from array
    prevSelectedFilters.splice($.inArray(filter + '_' + selectedOption, prevSelectedFilters) ,1 );
    prevSelectedFilters.splice($.inArray(filter, prevSelectedFilters) ,1 );
    // Remove label
    $(clickedFilterItem).remove();
}
