/**
 * Notification Button
 */
$(document).on('click', 'button.notification-button', function (e) {
    e.preventDefault();
    let _token = $("input[name=_token]").val();
    let readMessageUrl = $(this).data('read-message-url');
    let detailUrl = $(this).data('detail-url');
    return $.ajax({
        url: readMessageUrl,
        type: 'POST',
        data: {
            '_method': 'POST',
            '_token': _token
        },
        success: function (response) {
            window.location.href = detailUrl;
        },
        error: function (xhr) {
            switch (parseInt(xhr.status, 10)) {
                case 500:
                    Swal.fire({
                        icon: "error",
                        title: typeof xhr.responseJSON === "undefined" ? __('An error has occurred.') : xhr.responseJSON.message,
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: "bg-info"
                        }
                    });
                    break;
            }
        }
    });
});
