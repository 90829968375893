/**
 * Init Date Range Picker.
 */
window.initDateRangePicker = function(id, callback, timePicker = true) {
    let format = daterangepicker_format;
    if (timePicker) {
        format += " HH:mm";
    }
    daterangepicker_locale.format = format;
    $("#"+id+"_range").daterangepicker({
        "timePicker": timePicker,
        "timePicker24Hour": true,
        "timePickerIncrement": 5,
        "applyButtonClasses": "btn-orange",
        "autoUpdateInput": false,
        "linkedCalendars": false,
        "locale": daterangepicker_locale
    });
    let range = $("#"+id+"_range").val();
    if (range.length > 0) {
        $("#"+id+"_range").closest('div').find('.reset-daterangepicker').show();
    }
    $("#"+id+"_range").on("apply.daterangepicker", function(ev, picker) {
        if (picker.startDate && picker.endDate) {
            $("#"+id+"_range").val(picker.startDate.format(format) + " / " + picker.endDate.format(format));
            $("#"+id+"_from").val(picker.startDate.format(format));
            $("#"+id+"_to").val(picker.endDate.format(format));
        }
        $("#"+id+"_range").closest('div').find('.reset-daterangepicker').show();
        if (typeof callback !== typeof undefined) {
            if (typeof callback === "function") {
                callback();
            } else {
                let fn = window[callback];
                if (typeof fn === "function") {
                    fn();
                }
            }
        }
    });
    $("#"+id+"_range").on("show.daterangepicker", function() {
        if ($(this).val().length > 0) {
            let from = $("#"+id+"_from").val();
            let to = $("#"+id+"_to").val();
            $("#"+id+"_range").data('daterangepicker').setStartDate(from);
            $("#"+id+"_range").data('daterangepicker').setEndDate(to);
            $("#"+id+"_range").updateView();
        }
    });
    $("#"+id+"_range").on("cancel.daterangepicker", function() {
        let today = new Date();
        $("#"+id+"_range").data('daterangepicker').setStartDate(today);
        $("#"+id+"_range").data('daterangepicker').setEndDate(today);
        $("#"+id+"_range").val('');
        $("#"+id+"_from").val('');
        $("#"+id+"_to").val('');
        $("#"+id+"_range").closest('div').find('.reset-daterangepicker').hide();
        if (typeof callback !== typeof undefined) {
            if (typeof callback === "function") {
                callback();
            } else {
                let fn = window[callback];
                if (typeof fn === "function") {
                    fn();
                }
            }
        }
    });
    $(document).on("keyup", "#"+id+"_range", function() {
        if ($(this).val().length === 0) {
            let today = new Date();
            $("#"+id+"_range").data('daterangepicker').setStartDate(today);
            $("#"+id+"_range").data('daterangepicker').setEndDate(today);
            $("#"+id+"_range").val('');
            $("#"+id+"_from").val('');
            $("#"+id+"_to").val('');
            $("#"+id+"_range").data('daterangepicker').hide();
            $("#"+id+"_range").closest('div.form-group').find('.reset-daterangepicker').hide();
            if (typeof callback !== typeof undefined) {
                if (typeof callback === "function") {
                    callback();
                } else {
                    let fn = window[callback];
                    if (typeof fn === "function") {
                        fn();
                    }
                }
            }
        }
    });
};
