/**
 * Color Cell Style.
 */
window.colorCellStyle = function(value, row) {
    if (!value) {
        return false;
    }
    return {
        css: {
            "background-color": row.color
        }
    };
};
