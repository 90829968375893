/**
 * Is Online.
 */
window.isOnline = function() {
    if (window.navigator.onLine) {
        setOnline();
    } else {
        setOffline();
    }
};

/**
 * Set Offline.
 */
window.setOffline = function() {
    $('.alert-offline').show();
    $('.btn').each(function(k, button) {
        if (!$(button).is(":disabled")) {
            $(button)
                .attr('disabled', true)
                .addClass('button-offline');
        }
    });
};

/**
 * Set Online.
 */
window.setOnline = function() {
    $('.alert-offline').hide();
    $('.button-offline')
        .attr('disabled', false)
        .removeClass('button-offline');
};

/**
 * Check Online.
 */
window.checkOnline = async function() {
    let environment = $('.environment').html();
    if (environment === "local") {
        return false;
    }

    if (!window.navigator.onLine) {
        return false;
    }

    // avoid CORS errors with a request to your own origin
    const url = new URL(window.location.origin);

    // random value to prevent cached responses
    url.searchParams.set('ts', new Date().getTime());

    try {
        const response = await fetch(
            url.toString(),
            { method: 'HEAD' },
        );
        if (response.ok) {
            setOnline();
        } else {
            setOffline();
        }
    } catch {
        setOffline();
    }
}

window.addEventListener('offline', (e) => {
    setOffline();
});

window.addEventListener('online', (e) => {
    setOnline();
});

setInterval(checkOnline, 10000); // every 10 seconds.
