/**
 * Datepicker.
 */
window.datepicker = function() {
    $(".datepicker").datepicker({
        dateFormat: datepicker_format ? datepicker_format : "dd-mm-yy",
        changeMonth: true,
        changeYear: true,
        regional: locale,
        yearRange: "1920:c"
    });
};
