import './bootstrap';
// require('./bootstrap');

// Animate On Scroll
window.AOS = require('aos');

// Bootbox
window.bootbox = require('bootbox');

// Bootstrap Date Range Picker
require('bootstrap-daterangepicker');

// Bootstrap Table

window.bootstrap = {
    Tooltip: {
        VERSION: "5"
    }
}

require('bootstrap-table');
require('tableexport.jquery.plugin')
require('bootstrap-table/dist/extensions/export/bootstrap-table-export');
require('bootstrap-table/dist/extensions/custom-view/bootstrap-table-custom-view');

// Chosen
require('chosen-js');

// jQuery - Date Time Picker
require("jquery-datetimepicker");

// Jquery UI
require('jquery-ui/dist/jquery-ui');

// jQuery UI - autocomplete
require("jquery-ui/ui/widgets/autocomplete");

// jQuery UI - datepicker
require("jquery-ui/ui/widgets/datepicker");

// jQuery UI - slider
require("jquery-ui/ui/widgets/slider");

// jQuery UI - sortable
require("jquery-ui/ui/widgets/sortable");

// jsTree
require("jstree");

// Moment.js
require('moment');

// Tagsinput
require('bootstrap-tagsinput');

// SweetAlert2
window.Swal = require('sweetalert2');

// Flatpickr
window.flatpickr = require('flatpickr');

// App

require('./app/actions.js');
require('./app/buttonToggle.js');
require('./app/chosen.js');
require('./app/colorCellStyle.js');
require('./app/confirm.js');
require('./app/datepicker.js');
require('./app/daterangepicker.js');
require('./app/dragAndDropFileUploading.js');
require('./app/errors.js');
require('./app/filtering.js');
require('./app/flatpickr.js');
require('./app/fontAwesome.js');
require('./app/i18n.js');
require('./app/modals.js');
require('./app/numberInput.js');
require('./app/online.js');
require('./app/password.js');
require('./app/rangeSlider.js');
require('./app/ready.js');
require('./app/search.js');
require('./app/setLabelFilters.js');
require('./app/sprintf.js');
require('./app/tables.js');
require('./app/tabs.js');
require('./app/tagsinput.js');
require('./app/tooltip.js');
require('./app/validation.js');

// Content
require('./content/employees/sendMessage.js');

// Formatters

require('./app/formatters/apis/azure/authentication/detail.js');
require('./app/formatters/apis/azure/tokens/detail.js')
require('./app/formatters/apis/azure/users/detail.js')
require('./app/formatters/apis/efficy/authentication/detail.js');
require('./app/formatters/apis/efficy/companies/detail.js');
require('./app/formatters/apis/efficy/locations/detail.js');
require('./app/formatters/apis/efficy/projects/detail.js');
require('./app/formatters/apis/google_geocode/requests/detail.js');
require('./app/formatters/apis/mailgun/logs/show.js');
require('./app/formatters/auditDetail.js');
require('./app/formatters/boolean.js');
require('./app/formatters/customers/show.js');
require('./app/formatters/projects/view.js');
require('./app/formatters/delete.js');
require('./app/formatters/edit.js');
require('./app/formatters/fontAwesome.js');
require('./app/formatters/highlight.js')
require('./app/formatters/linkedIn.js');
require('./app/formatters/photo.js');
require('./app/formatters/show.js');
require('./app/formatters/tags.js');
require('./app/formatters/yesNo.js');
require('./app/formatters/url.js');

// Functions

require('./functions/delete');
require('./functions/left-menu');
require('./functions/notifcations');


