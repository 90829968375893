/**
 * Number Input.
 */
window.numberInput = function() {
    let html = '';
    html += '<div class="quantity-nav">';
    html += '<button type="button" class="quantity-button quantity-up">&#xf106;</button>';
    html += '<button type="button" class="quantity-button quantity-down">&#xf107</button>';
    html += '</div>';
    $(".quantity").append(html);
    $.each($(document).find('.quantity'), function () {
        let spinner = $(this),
            input = spinner.find('input[type="number"]'),
            btnUp = spinner.find('.quantity-up'),
            btnDown = spinner.find('.quantity-down'),
            min = input.attr('min'),
            max = input.attr('max');
        btnUp.click(function () {
            let oldValue = parseFloat(input.val());
            let newVal = null;
            if (oldValue >= max) {
                newVal = oldValue;
            } else {
                newVal = oldValue + 1;
            }
            spinner.find("input").val(newVal);
            spinner.find("input").trigger("change");
        });
        btnDown.click(function () {
            let oldValue = parseFloat(input.val());
            let newVal = null;
            if (oldValue <= min) {
                newVal = oldValue;
            } else {
                newVal = oldValue - 1;
            }
            spinner.find("input").val(newVal);
            spinner.find("input").trigger("change");
        });
    });
};
