/**
 * LinkedIn Formatter.
 */
window.linkedInFormatter = function(value, row) {
    let html = '';
    if (value == null) {
        return html;
    }
    html += '<a href="'+value+'" target="_blank" rel="nofollow">';
    html += '<i class="fa fa-globe text-success"></i>';
    html += '</a>';
    return html;
};
