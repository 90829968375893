/**
 * Edit Formatter.
 */
window.editFormatter = function(value, row) {
    let html = '';
    if (row.allow_edit === "0") {
        return html;
    }
    html += '<a href="#" class="action action-edit" aria-label="Edit">';
    html += '<i class="fa fa-pencil"></i>';
    html += '</a>';
    return html;
};
