/**
 * Audit Detail Formatter.
 */
window.auditDetailFormatter = function(value, row) {
    let html = '';
    let json_old_values = JSON.parse(row.json_old_values);
    let json_new_values = JSON.parse(row.json_new_values);
    html += '<table class="table" style="background: none">';
    html += '<thead>';
    html += '<tr>';
    html += '<th style="padding: 8px; width: 220px !important">'+__('Column')+'</th>';
    if (row.event !== "created") {
        html += '<th style="padding: 8px; width: 220px !important">' + __('Old Value') + '</th>';
    }
    if (row.event !== "deleted") {
        html += '<th style="padding: 8px; width: 220px !important">' + __('New Value') + '</th>';
    }
    html += '<th>&nbsp;</th>';
    html += '</tr>';
    html += '</thead>';
    html += '<tbody>';
    let values = row.event === "created" ? json_new_values : json_old_values;
    if (values != null && Object.keys(values).length > 0) {
        let keys = Object.keys(values);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            html += '<tr>';
            // Column
            html += '<td style="padding: 8px">'+key+'</th>';
            // Old Value
            if (row.event !== "created") {
                html += '<td style="padding: 8px; background: #FFE9E9; color: #333">' + json_old_values[key] + '</th>';
            }
            // New Value
            if (row.event !== "deleted") {
                html += '<td style="padding: 8px; background: #E9FFE9; color: #333">' + json_new_values[key] + '</th>';
            }
            html += '<td>&nbsp;</td>';
            html += '</tr>';
        }
    }
    html += '</tbody>';
    html += '</table>';
    return html;
};
