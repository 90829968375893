/**
 * Customers Show Formatter.
 */
window.customersShowFormatter = function(value, row) {
    let html = '';
    html += '<a href="/accounts/show/'+row.account_id+'">';
    html += value;
    html += '</a>';
    return html;
}
