window.jQXHR = null;

/**
 * Search.
 */
function search(search) {
    if (jQXHR) {
        jQXHR.abort();
        jQXHR = null;
    }
    $('.search-results-holder').removeClass('d-none');
    if (search.length <= 2) {
        $('.min-three-characters').removeClass('d-none');
        $('.search-results').addClass('d-none');
        $('.searching').addClass('d-none');
        return;
    }
    $('.min-three-characters').addClass('d-none');
    $('.search-results').removeClass('d-none');
    $('.searching').removeClass('d-none');
    $('.search-results').html("");
    $('.search-results').append("");
    jQXHR = $.ajax({
        url: searchUrl + '?search=' + search,
        type: "GET",
        success: function (response) {
           $('.searching').addClass('d-none');
           $('.search-results').html("");
           $('.search-results').append(response);
        },
        error: function (xhr) {
            switch (parseInt(xhr.status, 10)) {
                case 500:
                    Swal.fire({
                        icon: "error",
                        title: typeof xhr.responseJSON === "undefined" ? __('An error has occurred.') : xhr.responseJSON.message,
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: "bg-info"
                        }
                    });
                    break;
            }
        }
    });
}

$(document).on('keyup', 'form.global-search input', function () {
    search($(this).val());
});

$(document).on('click', 'button.btn-close-search-results', function (e) {
    e.preventDefault();
    $('.search-results-holder').addClass('d-none');
});

$(document).mouseup(function(e) {
    let container = $(".search-results-holder");
    let secondContainer = $(".global-search-field");
    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0 && !secondContainer.is(e.target) && secondContainer.has(e.target).length === 0) {
        container.addClass('d-none');
    }
});

$(document).keypress('.global-search', function (e) {
    let key = event.keyCode || event.which;
    if (key === 13) {
        e.preventDefault();
        return false;
    }
});

$(document).keyup('.global-search', function (e) {
    let key = event.keyCode || event.which;
    if (key === 13) {
        e.preventDefault();
        return false;
    }
});
