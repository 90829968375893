/**
 * Button Toggle.
 */
window.buttonToggle = function(button) {
    let toggle = button.attr("data-toggle");
    let status = button.attr("data-status");
    switch (status) {
        case "on":
            button.attr("data-status", "off");
            button.find("i").removeClass("fa-toggle-on");
            button.find("i").addClass("fa-toggle-off");
            button.find("span").empty().html(__("Show"));
            break;
        case "off":
            button.attr("data-status", "on");
            button.find("i").removeClass("fa-toggle-off");
            button.find("i").addClass("fa-toggle-on");
            button.find("span").empty().html(__("Hide"));
            break;
    }
    $("#" + toggle).toggle();
};
