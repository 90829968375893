/**
 * Apis Mailgun Logs Show Formatter.
 */
window.apisMailgunLogsShowFormatter = function(value, row) {
    let html = '';
    html += '<a href="/apis/mailgun/show/'+row.id+'" class="btn btn-outline-primary btn-sm">';
    html += '<i class="fa fa-eye"></i>';
    html += '</a>';
    return html;
}
