window.tabRequests = [];

/**
 * Tab.
 */
window.tab = function(url, id) {
    if (tabRequests.includes(url)) {
        return false;
    }
    addTabRequest(url);
    $.ajax({
        url: url,
        type: "GET",
        success: function(data) {
            removeTabRequest(url);
            $(document).find('.detail-modal .tab-pane').removeClass('active');
            $(document).find('.detail-modal .nav-link').removeClass('active');
            $('a[data-id="'+id+'"]').tab("show");
            $("#"+id).empty().html(data);
            $(".form-control-chosen").chosen({
                allow_single_deselect: true,
                width: "100%",
            });
            if (tabs.hasOwnProperty(id) === false) {
                return false;
            }
            if (tabs[id].tables) {
                if (typeof tables !== "undefined") {
                    tables = $.extend({}, tables, tabs[id].tables);
                    initBootstrapTables();
                } else {
                    tables = tabs[id].tables;
                    initBootstrapTables();
                }
            }
            // @todo would be better to use in callback instead of global.
            flatpickr();
            numberInput();
            if (typeof tabs[id].callback !== typeof undefined) {
                let fn = window[tabs[id].callback];
                if (typeof fn === "function") {
                    fn();
                }
            }
        },
        error: function (xhr) {
            removeTabRequest(url);
            switch (parseInt(xhr.status, 10)) {
                case 500:
                    Swal.fire({
                        icon: "error",
                        title: typeof xhr.responseJSON === "undefined" ? __('An error has occurred.') : xhr.responseJSON.message,
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: "bg-info"
                        }
                    });
                    break;
            }
        },
    });
};

/**
 * Add Tab Request,
 */
window.addTabRequest = function(url) {
    tabRequests.push(url);
};

/**
 * Remove Tab Request,
 */
window.removeTabRequest = function(url) {
    let index = tabRequests.indexOf(url);
    if (index > -1) {
        tabRequests.splice(index, 1);
    }
};

/**
 * Init Tabs.
 */
window.initTabs = function() {
    let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    $(document).on('click', 'a[data-toggle="tab"]', function (e) {
        let url = $(this).attr("data-url");
        let id = $(this).attr("data-id");
        if (isChrome) {
            window.location.hash = "";
        } else if (!$(this).attr("data-dont-toggle-to-hash")) {
            window.location.hash = "#"+id;
        }
        if (url) {
            tab(url, id);
        }
    });
    if (typeof tabs !== "undefined") {
        let id = '';
        if (window.location.hash) {
            id = window.location.hash.split("#")[1];
        } else if (tabs.hasOwnProperty('default') !== false) {
            id = tabs.default.id;
        } else {
            return false;
        }
        let url = $('a[data-id="'+id+'"]').attr("data-url");
        if (url) {
            tab(url, id);
        } else {
            $('a[data-id="'+id+'"]').tab("show");
        }
    }
};
