(function() {
    'use strict';
    window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        let forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        let validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();
$('form').on('submit', function(){
    $('.form-control-chosen[required]').each(function(){
        if ( $( this ).val() === '' ) $( this ).next().removeClass('is-valid').addClass('is-invalid');
        else $( this ).next().removeClass('is-invalid').addClass('is-valid');
    });
});
