/**
 * Apis Efficy Companies Detail Formatter.
 */
window.apisEfficyCompaniesDetailFormatter = function(value, row) {
    let html = "";
    if (row.request_headers) {
        html += '<div class="form-group">';
        html += "<label>"+__("Request-Headers:")+"</label>";
        html += '<textarea class="form-control" rows="3">'+row.request_headers+"</textarea>";
        html += "</div>";
    }
    if (row.request_content) {
        html += '<div class="form-group">';
        html += "<label>"+__("Request-Content:")+"</label>";
        html += '<textarea class="form-control" rows="3">'+row.request_content+"</textarea>";
        html += "</div>";
    }
    if (row.response) {
        html += '<div class="form-group">';
        html += "<label>"+__("Response:")+"</label>";
        html += '<textarea class="form-control" rows="3">'+row.response+"</textarea>";
        html += "</div>";
    }
    return html;
};
