/**
 * Flatpicker.
 */
window.flatpickr = function() {
    $(".flatpickr").flatpickr({
        dateFormat: datepicker_format ? datepicker_format : "d-m-Y"
    });
    $(".flatpickr").on('change', function () {
       if ($(this).hasClass('flatpickr-overview')) {
           $(this).parent('.form-group').removeClass('py-3');
           $(this).parent('.form-group').addClass('py-1');
           $(this).addClass('flatpickr-overview-block ');
       }
    });
};
