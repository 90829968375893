$(document).ready(function() {
    let leftColMenuTimer;
    $(".left-menu ul").mouseenter(function () {
        leftColMenuTimer = setTimeout(function () {
            $('.left-menu').addClass('hover');
        }, 100);
    }).mouseleave(function () {
        clearTimeout(leftColMenuTimer);
        $('.left-menu').removeClass('hover');
    });
});
