/**
 * Delete Formatter.
 */
window.deleteFormatter = function(value, row) {
    let html = '';
    if (row.allow_delete === 0 || row.allow_delete === "0") {
        return html;
    }
    html = '';
    html += '<a href="#" class="action action-destroy" aria-label="' + __("Delete") + '" data-id="' + row.id + '">';
    html += '<i class="fa fa-times"></i>';
    html += '</a>';
    return html;
};
