/**
 * Reset Filtering.
 */
window.resetFiltering = function(button) {
    $('.collapse').collapse('hide');

    // Fetch Select(s)

    let selects = button
        .closest('form')
        .find('select');

    // Clear Select(s)

    if (selects.length > 0) {
        selects.each(function () {
            let select = $("#"+$(this).attr('id'));
            if (select.prop('multiple')) {
                select.val([]);
            } else {
                select.val('');
            }
            select.trigger("chosen:updated");
        });

    }

    // Fetch Input(s)

    let inputs = button
        .closest('form')
        .find('input');


    // Clear Input(s)

    if (inputs.length > 0) {
        inputs.each(function (key, input) {
            if ($(this).attr('name') === '_token') {
                return true;
            }
            if ($(this).hasClass('flatpickr')) {
                let flatpick = document.querySelector('#' + $(this).attr('id'))._flatpickr;
                flatpick.clear();
            }
            if ($(this).hasClass('filter-range'))  {
                //
            }
            if ($(this).attr('type') === 'text') {
                $(this).val("");
            } else {
                if ($(this).attr('data-clear-all-filters') === 'false') {
                    // Select all checkboxes in the Type dropdown (Customers, Prospects en Resources).
                    $(this).prop("checked", true);
                } else {
                    $(this).prop("checked", false);
                }
            }
        });
    }

    // Table
    let id = button.data('table');

    if (id) {
        // Reset Search
        $("#" + id).bootstrapTable('resetSearch', '');
    }

    // Set Prev Filtering
    setPrevFiltering();
}

/**
 * Set Prev Filtering.
 */
window.setPrevFiltering = function () {
    $(document).find('.checked-filtering').each(function () {
        checkedFiltering($(this));
    });
    $(document).find('.selected-filtering').each(function () {
        selectedFiltering($(this));
    });
    $(document).find('.range-filtering').each(function () {
        rangeFiltering($(this));
    });
}

/**
 * Checked Filtering.
 */
window.checkedFiltering = function (customFiltering) {
    let optionsChecked = 0;
    $(customFiltering).find('input').each(function (item) {
        if ($(this).is(':checked')) {
            optionsChecked++;
        }
    });
    let label = $(customFiltering).find('.label span');
    $(label).removeClass('font-weight-600');
    $(label).find('.quantity').remove();
    if (optionsChecked) {
        $(label).addClass('font-weight-600');
        let generatedQuantity = '<span class="quantity badge quantity-badge bg-primary ms-2">';
        generatedQuantity += optionsChecked;
        generatedQuantity += '</span>';
        $(label).append(generatedQuantity);
    }
}

/**
 * Selected Quantity.
 */
window.selectedFiltering = function (selectedFiltering) {
    let optionsSelected = 0;
    $(selectedFiltering).find('option').each(function (item) {
        if ($(this).is(':selected')) {
            optionsSelected++;
        }
    });
    let label = $(selectedFiltering).find('.label span');
    $(label).removeClass('font-weight-600');
    $(label).find('.quantity').remove();
    if (optionsSelected) {
        $(label).addClass('font-weight-600');
        let generatedQuantity = '<span class="quantity badge quantity-badge bg-primary ms-2">';
        generatedQuantity += optionsSelected;
        generatedQuantity += '</span>';
        $(label).append(generatedQuantity);
    }
}

/**
 * Range Filtering.
 */
window.rangeFiltering = function (rangeFiltering) {
    let min = rangeFiltering.find('input:first').val();
    let max = rangeFiltering.find('input:last').val();
    let label = $(rangeFiltering).find('.label span');
    $(label).removeClass('font-weight-600');
    if (min && max) {
        $(label).addClass('font-weight-600');
    }
}

$(document).ready(function() {
    // Set Prev Filtering
    setPrevFiltering();
    // Reset Filtering
    $(document).on("click", ".clear-selection", function(e) {
        e.preventDefault();
        resetFiltering($(this));
    });
    // Checked Filtering
    $(document).on("change", ".checked-filtering .filter", function (e) {
        e.preventDefault();
        checkedFiltering($(this).closest('.checked-filtering'));
    });
    // Selected Filtering
    $(document).on("change", ".selected-filtering .filter", function (e) {
        e.preventDefault();
        selectedFiltering($(this).closest('.selected-filtering'));
    });
    // Range Filtering
    $(document).on("change", ".range-filtering .filter", function (e) {
        e.preventDefault();
        rangeFiltering($(this).closest('.range-filtering'));
    });
});
