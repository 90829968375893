$(".font-awesome-hover").mouseenter(function() {
    $(this)
        .find('i')
        .css("--fa-primary-color", "black")
        .css("--fa-secondary-color", "#F18500")
        .css("--fa-secondary-opacity", 0.9)
}).mouseleave(function() {
    $(this)
        .find('i')
        .css("--fa-primary-color", "")
        .css("--fa-secondary-color", "")
        .css("--fa-secondary-opacity", 0.5)
});
