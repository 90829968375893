/**
 * ProjectsView Formatter.
 */
window.projectsViewFormatter = function(data) {
    const templates_project = $('#templates_project').html();
    const templates_note = $('#templates_note').html();
    const templates_carousel = $('#templates_carousel').html();
    let html = '';
    let view = '';
    $.each(data, function (i, row) {
        html = templates_project;
        // Archived
        if (parseInt(row.archived, 10) === 1) {
            html = html.replace('archived float-left d-none', 'archived float-left');
        }
        // Edit Project
        if (row.permissions.projects_edit === "false") {
            html = html.replace('projects-edit-holder', 'projects-edit-holder d-none');
        }
        html = html.replace('%ROUTE_PROJECTS_EDIT%', row.routes.projects_edit);
        // Images
        if (row.images.length === 0) {
            html = html.replace('%IMAGES%', '');
        } else {
            let carousel = templates_carousel;
            let indicators = '';
            let items = '';
            carousel = carousel.replaceAll('%PROJECT_ID%', row.id);
            $.each(row.images, function(k, v) {
                indicators += '<button id="carousel_indicator_'+v.id+'" type="button" data-bs-target="#carousel_'+v.id+'" data-bs-slide-to="'+k+'" '+(k === 0 ? 'class="active"' : '')+' aria-current="true" aria-label=""></button>';
                items += '<div id="carousel_item_'+v.id+'" class="carousel-item '+(k === 0 ? 'active' : '')+'" data-image-id="'+v.id+'" data-destroy="'+v.routes.delete_image+'">';
                items += '<img src="'+v.preview+'" class="cursor-zoom-in d-block w-100" alt="" data-fancybox="gallery_'+row.id+'" data-src="'+v.original+'">';
                items += '</div>';
            });
            carousel = carousel.replaceAll('%INDICATORS%', indicators);
            carousel = carousel.replaceAll('%ITEMS%', items);
            html = html.replace('%IMAGES%', carousel);
            html = html.replace('images-holder', 'images-holder d-none');
        }
        // Delete Photo
        if (row.permissions.images_destroy === "false"
        || row.images.length === 0) {
            html = html.replace('delete-photo-holder', 'delete-photo-holder d-none');
        }
        // Add Photo
        if (row.permissions.images_create === "false") {
            html = html.replace('add-photo-holder', 'add-photo-holder d-none');
        }
        html = html.replace('%ROUTE_IMAGES_CREATE%', row.routes.images_create);
        // Capacity
        if (row.capacity.length === 0) {
            html = html.replace('capacity-holder', 'capacity-holder d-none');
        }
        // Width x height
        if (row.width_x_height.length === 0) {
            html = html.replace('width-x-height-holder', 'width-x-height-holder d-none');
        }
        // Date build
        if (row.builded_at.length === 0) {
            html = html.replace('date-build-holder', 'date-build-holder d-none');
        }
        // Date next service
        if (row.next_service_at.length === 0) {
            html = html.replace('next-service-at-holder', 'next-service-at-holder d-none');
        }
        // Date last maintenance
        if (row.last_maintenance_at.length === 0) {
            html = html.replace('last-maintenance-at-holder', 'last-maintenance-at-holder d-none');
        }
        // Variables
        html = html.replaceAll('%PROJECT_ID%', row.id);
        html = html.replaceAll('%REFERENCE%', row.reference);
        html = html.replaceAll('%NAME%', row.name);
        html = html.replace('%CAPACITY%', row.capacity);
        html = html.replace('%BUILDED_AT%', row.builded_at);
        html = html.replace('%WIDTH_X_HEIGHT%', row.width_x_height);
        html = html.replace('%NEXT_SERVICE_AT%', row.next_service_at);
        html = html.replace('%LAST_MAINTENANCE_AT%', row.last_maintenance_at);
        // Project location
        html = html.replace('%ADDRESS%', row.project_location.address);
        html = html.replace('%POSTAL_CODE%', row.project_location.postal_code);
        html = html.replace('%CITY%', row.project_location.city);
        html = html.replace('%COUNTRY%', row.project_location.country);
        // Main Contact
        html = html.replace('%EMPLOYEE%', row.employee);
        // Notes
        let notes = '';
        if (row.notes.length > 0) {
            $.each(row.notes, function(k, v) {
                let note = templates_note;
                note = note.replaceAll('%NOTE_ID%', v.id);
                note = note.replace('%DESCRIPTION%', v.description);
                note = note.replace('%CREATED_AT%', v.created_at);
                note = note.replaceAll('%NAME%', v.user.name);
                note = note.replaceAll('%AVATAR%', v.user.small_avatar);
                let deleteNote = '';
                if (parseInt(userId, 10) === parseInt(v.user.id, 10)) {
                    deleteNote = '<a class="button-delete-project-note btn btn-sm btn-outline-danger py-0 px-1" data-id="' + v.id + '">';
                    deleteNote += '<i class="fa fa-xmark me-1"></i>';
                    deleteNote += __('Delete');
                    deleteNote += '</a>';
                }
                note = note.replace('%DELETE_NOTE%', deleteNote);
                let borderBottom = (k === (row.notes.length - 1)) ? '' : 'border-bottom';
                note = note.replaceAll('%BORDER_BOTTOM%', borderBottom);
                notes += note;
            });
        }
        html = html.replace('%NOTES%', notes);
        // Add note
        html = html.replace('%ROUTE_NOTES_CREATE%', row.routes.notes_create);
        view += html;
    })
    return `<div class="row mx-0">${view}</div>`
}
