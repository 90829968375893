/**
 * Range Slider.
 */
window.rangeSlider = function() {
    $(document).find(".range-slider").each(function () {
        $(this).slider({
            range: true,
            min: $(this).data('min'),
            max: $(this).data('max'),
            values: [$(this).attr('data-filter-min'), $(this).attr('data-filter-max')],
            slide: function(event, ui) {
                $(this).attr('data-filter-min', ui.values[0]);
                $(this).attr('data-filter-max', ui.values[1]);
                $('input[name="' + $(this).data('name') + '_min"]').val(ui.values[0]);
                $('input[name="' + $(this).data('name') + '_max"]').val(ui.values[1]).change();
            }
        });
    });
};
