/**
 * Tags Formatter.
 */
window.tagsFormatter = function(value) {
    let html = '';
    if (value == null) {
        return html;
    }
    let a = value.split(",");
    for (i = 0; i < a.length; i++) {
        html += '<span class="badge rounded-pill bg-primary" style="margin-right: 6px">'+a[i]+'</span>';
    }
    return html;
};
