/**
 * Resize Chosen.
 */
window.resizeChosen = function() {
    setTimeout(function() {
        $(".chosen-container").each(function() {
            $(this).attr('style', 'width: 100%');
        });
    }, 1100);
};
