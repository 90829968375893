/**
 * Send Message.
 */
window.sendMessage = function(button) {
    let _token = $("input[name=_token]").val();
    let id = button.attr('data-id');
    let name = button.attr('data-name');
    bootbox.prompt({
        title: __('Send message to:')+' '+name,
        inputType: 'textarea',
        callback: function (result) {
            if (result === null) {
                return;
            }
            return $.ajax({
                url: route('send_message.store', id),
                type: 'POST',
                data: {
                    '_token': _token,
                    'message': result
                },
                success: function (response) {
                    switch (response.status) {
                        case 'ERROR':
                            Swal.fire({
                                icon: 'error',
                                title: response.message,
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'bg-info'
                                }
                            });
                            break;
                        case 'OK':
                            Swal.fire({
                                icon: 'success',
                                title: response.message,
                                showConfirmButton: false,
                                timer: 3000
                            });
                            break;
                    }
                },
                error: function (xhr) {
                    switch (parseInt(xhr.status, 10)) {
                        case 500:
                            Swal.fire({
                                icon: "error",
                                title: typeof xhr.responseJSON === "undefined" ? __('An error has occurred.') : xhr.responseJSON.message,
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: "bg-info"
                                }
                            });
                            break;
                    }
                }
            });
        }
    });
}

/**
 * Button Send Message.
 */
$(".button-send-message").click(function(e) {
    e.preventDefault();
    sendMessage($(this));
});
