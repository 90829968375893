/**
 * Highlight Formatter.
 */
window.highlightFormatter = function(value, row) {
    let html = '';
    html += '<a href="#" class="action action-edit" aria-label="Edit">';
    html += '<i class="fa fa-pencil"></i>';
    html += '</a>';
    return html;
};
