/**
 * Init Bootstrap Table.
 */
window.initBootstrapTable = function(id, table, refresh) {
    $("#"+id).closest('.card-body').css('min-height', '480px');
    if (refresh) {
        $("#"+id).bootstrapTable("destroy");
    }
    $("#"+id).bootstrapTable({
        url: table.url,
        // iconsPrefix: "", // can't convert undefined to object
        icons: {
            paginationSwitchDown: 'far fa-angle-down',
            paginationSwitchUp: 'far fa-angle-up',
            refresh: 'far fa-sync',
            toggleOff: 'far fa-toggle-off',
            toggleOn: 'far fa-toggle-on',
            // columns: 'far fa-columns',
            // detailOpen: 'far fa-lg fa-plus-square',
            // detailClose: 'far fa-lg fa-minus-square',
            clearSearch: 'far fa-times',
            export: 'far fa-share-from-square',
        },
        paginationPreText: '<span class="fal fa-chevron-left"></span>',
        paginationNextText: '<span class="fal fa-chevron-right"></span',
        trimOnSearch: false,
        queryParams: function(params) {
            var r = {
                limit: params.limit,
                offset: params.offset,
                order: params.order,
                search: params.search,
                sort: params.sort
            };
            if (typeof table.ts === "undefined") {
                r.ts = new Date().getTime();
            }
            if (typeof table.filters !== "undefined") {
                var filters = {};
                $.each(table.filters, function(index, filter) {
                    var f = $("#" + filter);
                    if (f.data('custom-checkbox-filtering')) {
                        if ($("#" + filter + " input:checked").length) {
                            var multiple = [];
                            $("#" + filter + " input:checked").each(function(index2, selected) {
                                let val = $(selected).val();
                                multiple.push(val);
                            });
                            filters[filter] = JSON.stringify(multiple);
                        }
                    } else if (typeof f.attr("multiple") !== "undefined" && f.attr("multiple") !== false) {
                        var multiple = [];
                        $("#" + filter + " :selected").each(function(index2, selected) {
                            let val = $(selected).val();
                            multiple.push(val);
                        });
                        filters[filter] = JSON.stringify(multiple);
                    } else {
                        filters[filter] = f.val();
                    }
                });
                var r = $.extend({}, r, filters);
            }
            return r;
        },
        rowAttributes: function (row, index) {
            if (typeof row.show_url !== typeof undefined && row.show_url !== false) {
                return {
                    "data-show-url": row.show_url,
                    "class": "clickable"
                }
            }
            if (typeof row.modal_url !== typeof undefined && row.modal_url !== false) {
                if (row.is_updated) {
                    return {
                        "data-modal-url": row.modal_url,
                        "class": "modal-clickable highlighted"
                    }
                }
                return {
                    "data-modal-url": row.modal_url,
                    "class": "modal-clickable"
                }
            }
        },
        onCheck: function () {
            disableToolbarButtons(id);
        },
        onCheckAll: function () {
            disableToolbarButtons(id);
        },
        onUncheck: function () {
            disableToolbarButtons(id);
        },
        onUncheckAll: function () {
            disableToolbarButtons(id);
        },
        loadingTemplate: function (loadingMessage   ) {
            return '<div class="line"></div>' +
                '<div class="line"></div>' +
                '<div class="line"></div>' +
                '<div class="line"></div>' +
                '<div class="line"></div>';
        },
        onPostBody: function () {
            // Do Nothing
        },
        onLoadSuccess: function(data) {
            if (data.status === "ERROR") {
                Swal.fire({
                    icon: 'error',
                    title: data.message,
                    showConfirmButton: true,
                    customClass: {
                        confirmButton: 'bg-info'
                    }
                });
            }
            if (typeof table.callback !== typeof undefined) {
                var fn = window[table.callback];
                if (typeof fn === "function") {
                    fn();
                }
            }
            // Search Input
            let searchInput = $("#"+id).closest('.bootstrap-table').find('input.search-input');
            searchInput.removeClass('focus');
            if (searchInput.val()) {
                setTimeout(function () {
                    searchInput
                        .addClass('focus')
                        .focus();
                }, 20);
            }
            // Custom title
            var customTitle = $('.custom-title-holder');
            if (customTitle.length) {
                var customTitleCloned = customTitle.clone();
                $(customTitle).addClass('d-none');
                customTitleCloned.removeClass('d-none custom-title-holder');
                customTitleCloned.addClass('custom-title');
                $("#"+id).closest('.bootstrap-table').find('.bs-bars .custom-title').remove();
                $("#"+id).closest('.bootstrap-table').find('.bs-bars').append(customTitleCloned);
            }
            // Custom tabs
            var customTabs = $('.custom-tabs-holder');
            if (customTabs.length) {
                $("#" + id).closest('.bootstrap-table').find('.bs-bars .custom-tabs').remove();
                var customTabsCloned = customTabs.clone();
                $(customTabsCloned).removeClass('custom-tabs-holder d-none');
                $(customTabsCloned).addClass('custom-tabs');
                $("#" + id).closest('.bootstrap-table').find('.bs-bars').append(customTabsCloned);
            }
            // Custom search
            var search = $("#" + id).closest('.bootstrap-table').find('.search');
            if (search) {
                search.addClass('custom-search');
                $("#"+id).closest('.bootstrap-table').find('.bs-bars').append(search);
            }
            // Custom column selection
            var columnSelection = $("#" + id).closest('.bootstrap-table').find('.keep-open');
            if (columnSelection) {
                columnSelection.addClass('custom-selection float-end');
                $(columnSelection).find('span.caret').html("");
                $(columnSelection).find('span.caret').append(__('Columns'));
                $("#"+id).closest('.bootstrap-table').find('.bs-bars').append(columnSelection);
            }
            // Custom export
            var exportField = $("#" + id).closest('.bootstrap-table').find('.export');
            if (exportField) {
                exportField.addClass('custom-export float-end');
                $(exportField).find('span.caret').html("");
                $(exportField).find('span.caret').append(__('Export'));
                $("#"+id).closest('.bootstrap-table').find('.bs-bars').append(exportField);
            }
            // Custom tabs
            var tab = $("#" + id).data('tab');
            var customButtons = $('.custom-buttons-holder');
            if (tab) {
                customButtons = $('#tab-' + tab).find('.custom-buttons-holder');
            }
            $("#" + id).closest('.bootstrap-table').find('.bs-bars .custom-buttons').remove();
            var customButtonsCloned = customButtons.clone();
            $(customButtonsCloned).removeClass('custom-buttons-holder d-none');
            $(customButtonsCloned).addClass('custom-buttons');
            if ($(window).width() > 1060) {
                $("#" + id).closest('.bootstrap-table').find('.bs-bars').append(customButtonsCloned);
            } else if ($(window).width() > 590) {
                $("#" + id).closest('.card-body').find('h5').addClass('float-start');
                $("#" + id).closest('.card-body').find('.custom-buttons-holder').removeClass('d-none');
                $("#" + id).closest('.card-body').find('.custom-buttons-holder').addClass('mb-2');
            } else {
                $("#" + id).closest('.card-body').find('.custom-buttons-holder').removeClass('float-end d-none');
                $("#" + id).closest('.card-body').find('.custom-buttons-holder button').addClass('w-100');
                $("#" + id).closest('.card-body').find('.custom-buttons-holder a').addClass('w-100 mb-2');
            }
            $("#"+id).closest('.card-body').css('min-height', 0);
            // Set Prev Filtering
            setPrevFiltering();
            // Range Slider
            rangeSlider();
        },
        onLoadError: function(status, xhr) {
            Swal.fire({
                icon: "error",
                title: typeof xhr.responseJSON === "undefined" ? __('An error has occurred.') : xhr.responseJSON.message,
                showConfirmButton: true,
                customClass: {
                    confirmButton: "bg-info"
                }
            });
        }
    });
    if (typeof table.sortable != "undefined") {
        $("table#"+id+" tbody").sortable({
            handle: ".sortable",
            update: function(event, ui) {
                let _token = $("input[name=_token]").val();
                let data = $("table#"+id+" tbody i.sortable").map(function() {
                    return $(this).attr("data-id");
                }).get();
                return $.ajax({
                    data: {
                        '_token': _token,
                        data: data
                    },
                    type: "POST",
                    url: table.sortable.url
                });
            }
        });
    }
}

/**
 * Init Bootstrap Tables.
 */
window.initBootstrapTables = function() {
    if (typeof tables == "undefined") {
        return false;
    }
    $.each(tables, function(id, table) {
        initBootstrapTable(id, table, false);
    });
}

/**
 * Loading Template.
 */
window.loadingTemplate = function() {
    return '<div class="loading-template"><i class="fa-solid fa-spinner fa-spin-pulse fa-spin-reverse"></i></div>';
};

/**
 * Refresh Bootstrap Table.
 */
window.refreshBootstrapTable = function(id) {
    var table = tables[id];
    initBootstrapTable(id, table, true);
}

/**
 * Get Row Selections.
 */
window.getRowSelections = function(id) {
    return $.map($("#"+id).bootstrapTable('getSelections'), function(row) {
        return row;
    });
}

/**
 * Disable Toolbar Buttons.
 */
window.disableToolbarButtons = function(id) {
    let selectedRows = getRowSelections(id);
    if (selectedRows.length) {
        $(document).find('.toolbar-action-button').removeClass('disabled');
        $(document).find('.toolbar-action-button').attr('disabled', false);
    } else {
        $(document).find('.toolbar-action-button').addClass('disabled');
        $(document).find('.toolbar-action-button').attr('disabled', true);
    }
}

$(document).ready(function() {
    // Table Row
    $(document).on("click", "tr.clickable", function(e) {
        window.location = $(this).data('show-url');
        return false;
    });
});
