/**
 * Form Request Errors To Html.
 */
window.formRequestErrorsToHtml = function(data) {
    let responseText = JSON.parse(data.responseText);
    let html = '';
    html += '<div class="alert alert-danger mt-3" role="alert">';
    $.each(responseText.errors, function (key, value) {
        $.each(value, function (key2, error) {
            html += error+'<br>';
        });
    });
    html += '</div>';
    return html;
};

/**
 * Form Request Errors Validation.
 */
window.formRequestErrorsValidation = function(data, item = false) {
    let responseText = JSON.parse(data.responseText);
    $.each(responseText.errors, function (key, value) {
        key = key.replace('.', '_');
        let field = $('#'+key);
        if (item) {
            field = $(item).find('#'+key);
        }
        field.addClass('is-invalid');
        if (field.closest('.form-group').length) {
            field.closest('.form-group').addClass('has-error');
            field.closest('.form-group').find('.help-block').empty();
            field.closest('.form-group').append('<span class="help-block error-help-block w-100 d-block">'+value+'</span>');
        }
    });
};

/**
 * Remove Validation.
 */
window.removeValidation = function(obj) {
    obj.removeClass('is-invalid');
    if (obj.closest('.form-group').length) {
        obj.closest('.has-error').removeClass('has-error');
        obj.closest('.form-group').find('.help-block').empty();
    }
};

/**
 * Remove Validation
 */
$(document).on('change', 'form select, form input', function () {
    removeValidation($(this));
});
