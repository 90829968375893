$(function() {
    $(document).find('.modal').each(function () {
        let modal = $(this);
        $(modal).hide();
    });
    $(document).on('hidden.bs.modal', '.modal', function () {
        let modal = $(this);
        $(modal).hide();
        let backdrop = $(document).find('.modal-backdrop');
        if (parseInt(backdrop.length, 10) > 0) {
            backdrop.remove();
        }
    });
    $(document).on('shown.bs.modal', '.modal', function () {
        let modal = $(this);
        let countTabs = modal.find('.tab-pane').length;
        countTabs = parseInt(countTabs, 10);
         if (countTabs > 0) {
             let closeModal = true;
             $.each(modal.find('.tab-pane'), function(k, v) {
               if (parseInt(v.innerHTML.length, 10) > 0) {
                   closeModal = false;
               }
             });
             if (closeModal === true) {
                 $(modal).hide();
             }
        }
        let backdrop = $(document).find('.modal-backdrop');
        if (parseInt(backdrop.length, 10) > 1) {
            backdrop.not(':first').remove();
        }
    });
});
