window.timerCollapse = null;

$(function() {
    "use strict";

    /**
     * Animate On Scroll.
     */
    AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false
    });

    /**
     * Button Confirm.
     */
    $(".button-confirm").click(function(e) {
        e.preventDefault();
        confirm($(this));
    });

    /**
     * Button Toggle.
     */
    $(".button-toggle").click(function(e) {
        e.preventDefault();
        buttonToggle($(this));
    });

    /**
     * Chosen.
     */
    resizeChosen();
    $(window).on("resize", resizeChosen);

    /**
     * Collapse.
     */

    $('.collapse').hover(function() {
        window.clearTimeout(timerCollapse);
        timerCollapse = window.setTimeout(function() {
            // Mouse Over
        }, 1500);
    }, function() {
        window.clearTimeout(timerCollapse);
        timerCollapse = window.setTimeout(function() {
            // Mouse Out
            $('.collapse').collapse('hide');
        }, 1500);
    });

    /**
     * Datepicker.
     */
    datepicker();

    /**
     * Date Range Picker.
     */
    $(".daterangepicker").daterangepicker();

    /**
     * Date Time Picker.
     */
    $(".datetimepicker").datetimepicker({
        format: "d-m-Y H:i",
        theme: "dark"
    });
});
